@import url('https://rsms.me/inter/inter.css');
html {
    font-family: 'Inter', sans-serif;
    width: 100%;
    height: 100%;
    background: #1c2127;
}

@supports (font-variation-settings: normal) {
    html {
        font-family: 'Inter var', sans-serif;
        width: 100%;
        height: 100%;
        background: #1c2127;
    }
}

body {
    margin: 0;
    font-size: calc(14px + (22 - 14) * ((100vw - 300px) / (1600 - 300)));
    line-height: calc(1.3em + (1.5 - 1.2) * ((100vw - 300px) / (1600 - 300)));
}
